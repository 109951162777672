<template>
  <div
    ref="houseAd"
    :class="containerClasses"
  >
    <div
      :class="textClasses"
      class="w-full flex items-center lg:justify-center space-x-3 md:space-x-2 leading-5"
    >
      <fa
        v-if="selectedVariant.show_icon"
        :class="iconClasses"
        :icon="['fal', customIcon]"
      />
      <span>
        <span v-html="selectedVariant.text.trim()" />
        <a
          v-if="hasLink"
          :rel="linkRel"
          :href="selectedVariant.link_url"
          :target="linkTarget"
          @click="trackClick"
        >
          <span :class="linkClasses">
            {{ linkText }}
          </span>
        </a>
      </span>
    </div>
    <button
      v-if="dismissable"
      type="button"
      :aria-label="$t('app.close')"
      :class="closeButtonClasses"
      @click="close"
    >
      <fa
        :icon="['fal', 'xmark']"
        class="md:absolute md:top-1/2 md:-translate-y-1/2 md:right-0"
      />
    </button>
  </div>
</template>

<script>
import HouseAdDismissBehavior from '@/enums/HouseAdDismissBehavior';
import HouseAdFullStripType from '@/enums/HouseAdFullStripType';
import HouseAd from '@/mixins/HouseAd';

export default {
  name: 'VetsterFullStrip',

  mixins: [HouseAd],

  computed: {
    closeButtonClasses() {
      return [
        'relative text-lg ml-6 leading-[unset] flex items-center',
        this.isInfo ? 'text-neutral-50' : '',
        this.isPromo ? 'text-neutral-700' : '',
        this.isPromoLight ? 'text-neutral-700' : '',
        this.isWarning ? 'text-neutral-700' : '',
      ];
    },

    containerClasses() {
      return [
        'w-full px-4 items-start sm:items-center justify-between leading-tight text-sm flex transition-all duration-500',
        this.isInfo ? 'bg-ocean-800 text-white' : '',
        this.isPromo ? 'bg-bubblegum-500 text-neutral-900' : '',
        this.isPromoLight ? 'bg-bubblegum-100 text-neutral-900' : '',
        this.isWarning ? 'bg-red-50 text-neutral-900' : '',
        this.isVisible ? this.enterAnimationClasses.join(' ') : this.leaveAnimationClasses.join(' '),
      ];
    },

    customIcon() {
      return this.selectedVariant.custom_icon || HouseAdFullStripType.getIcon(this.selectedVariant.type);
    },

    dismissable() {
      return this.selectedVariant.dismiss_behavior !== HouseAdDismissBehavior.NonDismissable;
    },

    enterAnimationClasses() {
      return ['h-auto', 'py-2.5', 'md:py-3', 'lg:py-2.5', 'visible'];
    },

    hasLink() {
      return this.selectedVariant.link_url && this.selectedVariant.link_text;
    },

    iconClasses() {
      return [
        'size-4 text-xl',
        this.isInfo ? 'text-white' : '',
        this.isPromo ? 'text-neutral-700' : '',
        this.isPromoLight ? 'text-bubblegum-500' : '',
        this.isWarning ? 'text-red-700' : '',
      ];
    },

    isInfo() {
      return this.selectedVariant.type === HouseAdFullStripType.Info || !this.selectedVariant.type;
    },

    isLinkTargetBlank() {
      return this.selectedVariant.link_target === '_blank';
    },

    isPromo() {
      return this.selectedVariant.type === HouseAdFullStripType.Promo;
    },

    isPromoLight() {
      return this.selectedVariant.type === HouseAdFullStripType.PromoLight;
    },

    isWarning() {
      return this.selectedVariant.type === HouseAdFullStripType.Warning;
    },

    leaveAnimationClasses() {
      return ['py-0', 'h-0', 'invisible'];
    },

    linkClasses() {
      return [
        'underline underline-offset-2 font-bold ml-1 inline-block',
        this.isInfo ? 'text-white' : '',
        this.isPromo ? 'text-neutral-900' : '',
        this.isPromoLight ? 'text-bubblegum-700' : '',
        this.isWarning ? 'text-neutral-900' : '',
      ];
    },

    linkRel() {
      return this.isLinkTargetBlank ? 'noreferrer' : '';
    },

    linkTarget() {
      return this.selectedVariant.link_target || '_self';
    },

    linkText() {
      return this.selectedVariant.link_text;
    },

    textClasses() {
      return this.isVisible ? '' : 'invisible';
    },
  },

  methods: {
    close() {
      const { classList } = this.$refs.houseAd;

      this.isVisible = false;
      this.enterAnimationClasses.forEach((className) => classList.remove(className));
      this.leaveAnimationClasses.forEach((className) => classList.add(className));
      this.resetMainContentPadding();

      setTimeout(this.dismiss, 500);
    },
  },
};
</script>
