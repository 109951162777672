export default {
  props: {
    hashId: {
      type: String,
      required: true,
    },

    houseAd: {
      type: Object,
      required: true,
    },

    selectedVariant: {
      type: Object,
      required: true,
    },
  },

  inject: [
    'dismiss',
    'trackClick',
    'trackView',
  ],

  data() {
    return {
      isVisible: false,
      // This value can be a scroll position in pixels, a target element's offset, or time in milliseconds.
      showAfterValue: null,
    };
  },

  mounted() {
    this.handleShowAfterBehavior();
  },

  methods: {
    adjustMainContentPadding() {
      this.$nextTick(() => {
        document.getElementById('main-content').style.paddingTop = `${document.getElementById('nav').offsetHeight}px`;
      });
    },

    handleHouseAdShowAfterPx(px) {
      this.showAfterValue = parseInt(px, 10);
      window.addEventListener('scroll', this.showHouseAdAfterPx);
      this.showHouseAdAfterPx();
    },

    handleHouseAdShowAfterTarget(selector) {
      this.showAfterValue = document.querySelector(selector);

      if (this.showAfterValue) {
        window.addEventListener('scroll', this.showHouseAdAfterTarget);
        this.showHouseAdAfterTarget();
      }
    },

    handleShowAfterBehavior() {
      const value = this.selectedVariant.show_after;

      if (value) {
        if (!Number.isNaN(parseInt(value, 10)) && value.endsWith('px')) {
          this.handleHouseAdShowAfterPx(value);
        } else if (!Number.isNaN(parseInt(value, 10)) && value.endsWith('ms')) {
          this.showHouseAdAfterTime(value);
        } else {
          this.handleHouseAdShowAfterTarget(value);
        }
      } else {
        this.showHouseAdAfterTime(500);
      }
    },

    resetMainContentPadding() {
      this.$nextTick(() => {
        document.getElementById('main-content').style.paddingTop = '';
      });
    },

    showHouseAd() {
      this.isVisible = true;
      this.adjustMainContentPadding();
      this.trackView();
    },

    showHouseAdAfterPx() {
      if (window.scrollY > this.showAfterValue) {
        this.showHouseAd();
        window.removeEventListener('scroll', this.showHouseAdAfterPx);
      }
    },

    showHouseAdAfterTarget() {
      if (window.scrollY > this.showAfterValue.offsetTop) {
        this.showHouseAd();
        window.removeEventListener('scroll', this.showHouseAdAfterTarget);
      }
    },

    showHouseAdAfterTime(ms) {
      this.showAfterValue = parseInt(ms, 10);
      setTimeout(this.showHouseAd, this.showAfterValue);
    },
  },
};
