<template>
  <div class="break-inside-avoid w-full shadow rounded-3xl p-10 mt-6">
    <p class="font-bold leading-5 text-ocean-950">
      {{ review.author }}
    </p>

    <div class="mt-2 flex justify-between min-h-6">
      <transition-group
        name="fade"
        appear
      >
        <fa
          v-for="i in review.rating_overall"
          :key="review.uuid + '-' + i"
          :icon="['fas', 'star']"
          class="size-4 text-bubblegum-500"
        />
        <fa
          v-for="i in 5 - review.rating_overall"
          :key="review.uuid + '-' + i"
          :icon="['fal', 'star']"
          class="size-4 text-bubblegum-500"
        />
      </transition-group>
      <p class="text-neutral-600 text-sm">
        {{ createdAtMonth }}<span v-if="review.address">, {{ review.address.city }}</span>
      </p>
    </div>

    <p class="mt-6 text-neutral-900">
      {{ review.body }}
    </p>

    <div class="mt-8">
      <p class="font-bold text-ocean-950">
        {{ review.reviewable.full_name }}
      </p>
      <p class="text-neutral-500 text-xs">
        {{ review.reviewable.designation }}, {{ review.reviewable.post_nominal }}
      </p>
    </div>
  </div>
</template>

<script>
import { monthYearFromISO } from '@/helpers/dateUtils';

export default {
  name: 'VetsterReviewCard',

  props: {
    review: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    createdAtMonth() {
      return monthYearFromISO(this.review.created_at);
    },
  },
};
</script>
