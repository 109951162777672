export default class HouseAdFullStripType {}
HouseAdFullStripType.Info = 'info';
HouseAdFullStripType.Promo = 'promo';
HouseAdFullStripType.PromoLight = 'promo-light';
HouseAdFullStripType.Warning = 'warning';

HouseAdFullStripType.getIcon = (type) => {
  switch (type) {
    case HouseAdFullStripType.Promo:
    case HouseAdFullStripType.PromoLight:
      return 'badge-dollar';
    case HouseAdFullStripType.Warning:
      return 'circle-exclamation';
    case HouseAdFullStripType.Info:
    default:
      return 'info-circle';
  }
};
