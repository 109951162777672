import { reactive } from 'vue';

import setGlobalProperty from '@/helpers/setGlobalProperty';

export default {
  install(app) {
    setGlobalProperty(app, '$layout', reactive({
      shareModalOpen: false,
      stickyNavOpen: true,
      closeShareModal() {
        this.shareModalOpen = false;
      },
      closeStickyNav() {
        this.stickyNavOpen = false;
      },
      openStickyNav() {
        this.stickyNavOpen = true;
      },
      toggleStickyNav() {
        this.stickyNavOpen = !this.stickyNavOpen;
      },
      toggleShareModal() {
        this.shareModalOpen = !this.shareModalOpen;
      },
    }));
  },
};
